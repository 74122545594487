import { useStaticQuery, graphql } from 'gatsby'

export default () => useStaticQuery(graphql`
  query TeaserQuery {
    allWorks {
      nodes {
        id
        url
        title
        text
        description
        contentStart {
          id
          type
          videoDesktop
          videoTablet
          videoMobile
          url
          alt
          width
          height
          sizes
          modified
        }
      }
    }
  }
`)
