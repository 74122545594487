import React from 'react'
import { Box } from 'react-raster'
import { TransitionLink } from 'gatsby-plugin-transitions'
import TeaserMedia from './media'
import useWindowSize from 'utils/useWindowSize'
// import useScrollTo from 'components/scrollTo'
import useStore from 'state'
import { useInView } from 'react-intersection-observer'

const TeaserLink = ({ el, work, size }) => {
  // const [to, setto] = useState(undefined)
  // useScrollTo({ to })
  const setY = useStore(state => state.setY)
  return (
    <Box cols={size}>
      <TransitionLink
        className='teaser__link'
        to={work.url}
        leave={{
          opacity: 0,
          transform: 'translate3d(0, -100vh, 0)',
          onStart: () => {
            const y = window.scrollY
            setY(y)
            // setto(window.scrollY + 3000),
          }
        }}
        enter={{
          transform: 'translate3d(0, 100vh, 0)',
          opacity: 0
        }}
        usual={{
          transform: 'translate3d(0, 0vh, 0)',
          opacity: 1
        }}
        mode='immediate'
      >
        <TeaserMedia work={work} element={el} />
      </TransitionLink>
    </Box>
  )
}
const TeaserWork = ({ work }) => {
  const [windowWidth] = useWindowSize()
  const [ref, animate] = useInView({
    threshold: 0,
    rootMargin: '0px',
    triggerOnce: true
  })

  return (
    <Box
      ref={ref}
      cols={12}
      bottom={2}
      className={`teaser__media`}
    >
      <Box style={`
        transition: transform 1s;
        transform: translate3d(0, ${animate ? '0vh' : '20vh'}, 0);
      `}>
        {windowWidth >= 1024
          ? work.contentStart.map(el => (
            <TeaserLink
              key={el.id}
              work={work}
              el={el}
              size={6}
            />
          ))
          : (
            <TeaserLink
              work={work}
              el={work.contentStart[0]}
              size={12}
            />
          )
        }
        <Box cols={[12, 12, 4]}>
          <h2 className='teaser__text size--0'>{work.title}</h2>
        </Box>
        {windowWidth >= 1024 && (
          <Box cols={8}>
            <div
              className='teaser__text size--0'
              dangerouslySetInnerHTML={{ __html: work.text }}
            />
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default TeaserWork
