import React from 'react'
import Image from 'components/image'
import Video from 'components/video'
// import { useTensionContext } from '../tension'

const TeaserMedia = ({ work, element }) => {
  // const [{ viewState }] = useTensionContext()

  switch (element.type) {
    case 'video':
      return (
        <Video
          srcDesktop={element.videoDesktop}
          srcTablet={element.videoTablet}
          srcMobile={element.videoMobile}
        />
      )
    case 'image':
    default:
      return (
        <Image
          className='teaser__image'
          sizes={element.sizes}
          ratio='1:1.777'
          alt={element.alt}
          url={element.url}
          title={work.url}
          style={`
            img {
              transition: opacity 0.5s;
              opacity: 0;
            }
            &.loaded img {
              opacity: 1;
            }
            background: rgba(0,0,0,0.1);
          `}
          show
        />
      )
  }
}

export default TeaserMedia
