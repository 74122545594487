import React from 'react'
import { graphql } from 'gatsby'

import Teasers from 'components/teasers'
import SEO from '../seo'
const Frontpage = ({ data, location }) => {
  return (
    <>
      <SEO
        title={'Showcase | ' + data.seo.title}
        description={data.seo.description}
        url={location.url}
      // image={location.image}
      />
      <Teasers
        location={location}
      />
    </>
  )
}

export const query = graphql`
  query {
    seo {
      title
      description
    }
  }
`

export default Frontpage
