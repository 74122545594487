import React from 'react'
import './index.scss'
import getQuery from './query'
import { Grid } from 'react-raster'
import TeaserWork from './work'
import gridProps from 'layouts/gridProps'

const Teasers = ({ location }) => {
  const { allWorks: { nodes: works } } = getQuery()

  return (
    <Grid
      {...gridProps}
      cols={12}
      className='teaser__media'
      top={['83vh', '83vh', '92vh', '92vh', '95vh']}
    >
      {works.map((work, index) => (
        <TeaserWork key={work.id} work={work} />
      ))}
    </Grid>
  )
}

export default Teasers
